<template>
    <layout-default>
        <section class="py-5">
            <div class="container py-5">
                <header class="mb-5">
                    <div class="row">
                        <div class="col-lg-9 mx-auto">
                        <p class="h2 text-uppercase text-primary mb-3">Besonderer Verordnungsbedarf (BVB)</p>
                        </div>
                    </div>
                </header>
                <div class="row">
                    <div class="col-lg-9 mx-auto">
                        <h5 class="h6 mb-4">Darauf kommt es bei der Verordnung gemäß der neuen Heilmittel-Richtlinie an:</h5>
                        <p align="justify" class="">2017 wurde der besondere Verordnungsbedarf (BVB) gemäß § 106 b SGB V in das Verfahren zur Wirtschaftlichkeitsprüfung eingeführt. Sie werden im Falle einer Prüfung aus dem Heilmittelausgabenvolumen des Arztes herausgerechnet und ermöglichen es Ärzten, für die gelisteten Diagnosen viele Heilmittel zu verordnen, ohne damit ihr Budget zu belasten oder einen Regress zu riskieren.</p>
                        <p align="justify" class="mb-4">Die Informationen zu BVB sind auch in der Praxis-Software hinterlegt. Außerdem können Sie anhand der Diagnoselisten ganz einfach extrabudgetäre Verordnungen nachprüfen.</p>
                        <h5 class="h6 mb-4">Die richtige Diagnose finden</h5>
                        <p align="justify" class="">Bei manchen Diagnosen ist der besondere Verordnungsbedarf befristet, zum Beispiel auf ein Jahr nach Akutereignis. Hier lassen sich aber oftmals andere, ebenfalls zutreffende Diagnosen finden, die diese zeitliche Einschränkung nicht haben (siehe unten das Beispiel "Schlaganfall"). Für Patienten ab vollendetem 70. Lebensjahr sind vor allem die unter „Geriatrische Syndrome" gelisteten Diagnosen interessant. Wer hier genau hinschaut und mit dem Arzt spricht, findet für diese Altersgruppe sicher Heilmittel, die sich extrabudgetär verordnen lassen. Sie können auch im Therapiebericht auf eine dieser Diagnosen hinweisen.</p>
                        <p align="justify">Die Diagnoseliste langfristiger Heilmittelbedarf/ besonderer Verordnungsbedarf finden Sie auf der Webseite der Kassenärztlichen Bundesvereinigung.</p>

                        <h5 class="h6 mb-4">Arzt muss BVB kennzeichnen</h5>
                        <p align="justify">Der Arzt muss seine extrabudgetären Verordnungen, die den besonderen Verordnungsbedarf und regionale Praxisbesonderheiten betreffen, selbst in der Praxis dokumentieren. Denn diese werden erst bei einer Wirtschaftlichkeitsprüfung aus seinem Heilmittelvolumen herausgerechnet. Das heißt, er wird immer in den Verordnungsstatistiken als auffällig erscheinen, die ihm seine KV schickt. Jedes ärztliche Praxisverwaltungssystem ermöglicht es aber, Verordnungen als extrabudgetär zu kennzeichnen, sodass der Arzt den Überblick über seine tatsächlichen Heilmittelausgaben behalten kann.</p>
                        <p class="mb-0">Weitere Informationen unter:</p>
                        <p class="mb-0"><a href="https://www.praxisfragen.de/s/artikel/Extrabudgetaeres-Verordnen-Besonderer-Verordnungsbedarf">https://www.praxisfragen.de/s/artikel/Extrabudgetaeres-Verordnen-Besonderer-Verordnungsbedarf</a></p>
                        <p><a href="https://www.kbv.de/htm1/heilmitte1.php#content49045">https://www.kbv.de/htm1/heilmitte1.php#content49045</a></p>
                    </div>
                </div>
            </div>
        </section>
    </layout-default>
</template>

<script>
import LayoutDefault from '../layouts/LayoutDefault.vue';

export default {
    name: 'Verordnungsbedarf',
    components: {
        LayoutDefault
    }
};
</script>
